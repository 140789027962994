import React from "react";
import { CouponInter } from "../../interface/interface";
import "./css/index.css";
type Props = {
  data: CouponInter;
  listType: "ready" | "use";
};

const MyCouponCard = ({
  data: {
    cpon_nm,
    cpon_discnt_rate,
    cpon_stat_cd,
    cpon_type_cd,
    cpon_apply_en_at,
    cpon_ctg_cd,
    cpon_apply_expired_fg,
  },
  listType,
}: Props) => {
  const FinishDate = new Date(cpon_apply_en_at);
  return (
    <div
      className={`my-coupon-card ${
        cpon_stat_cd === "99"
          ? cpon_type_cd === "normal"
            ? "coupon"
            : "invitation"
          : "use"
      }-type`}
    >
      <div className="coupon-content">
        <div className="type">
          {cpon_type_cd === "normal"
            ? `
            ${cpon_ctg_cd === "prjt" ? "프로젝트" : "밋업"}쿠폰
          `
            : "인비테이션"}
        </div>
        <div className="title">
          {cpon_nm} <br />
          {cpon_discnt_rate}% 할인쿠폰
        </div>
        <div className="period">
          {FinishDate.getFullYear()}년 {FinishDate.getMonth() + 1}월{" "}
          {FinishDate.getDate()}일까지 사용 가능
        </div>
      </div>
      <div className="coupon-line">
        <div className="circle" />
        <div className="line" />
        <div className="circle" />
      </div>
      <div className="coupon-check">
        {listType === "ready" ? (
          <div className="title">
            {cpon_type_cd === "normal"
              ? cpon_ctg_cd === "prjt"
                ? "프로젝트"
                : "밋업"
              : "일부"}
            <br />
            사용가능
          </div>
        ) : (
          <div className="title">{cpon_apply_expired_fg ? "만료" : "사용"}</div>
        )}
      </div>
    </div>
  );
};

export default MyCouponCard;
