import React, { useCallback, useEffect, useState } from "react";
import { Link, NavigateFunction, useOutletContext } from "react-router-dom";
import { Common_get_api } from "../../../api/get_api";
import EmtyList from "../../../components/EmtyList/EmtyList";
import NoticeCard from "../../../components/NoticeCard/NoticeCard";
import { ProjectCardInter } from "../../../interface/interface";
import { encrypto } from "../../../lib/lib";

const RoomNotice = () => {
  const {
    navigate,
    nowPrj,
    isLeader,
  }: {
    navigate: NavigateFunction;
    nowPrj: ProjectCardInter;
    isLeader: boolean;
  } = useOutletContext();
  const [NoticeData, setNoticeData] = useState([]);
  const __nav = useCallback(
    (id: string) => {
      navigate(`./detail/${encrypto(id)}`);
    },
    [navigate]
  );
  useEffect(() => {
    if (nowPrj) {
      Common_get_api(
        `/web/cmnt/1?bbs_id=1&ctts_ctg_cd=NOTICE&prjt_id=${nowPrj.prjt_id}`
      ).then(({ state, data }) => {
        if (state) {
          setNoticeData(data.lst);
        }
      });
    }
    return () => {};
  }, [nowPrj]);
  return (
    <div className="teampl-notice">
      <div className="teampl-notice-wrapper">
        {NoticeData.length > 0 ? (
          NoticeData.map((item, idx) => {
            return <NoticeCard key={idx} data={item} __nav={__nav} />;
          })
        ) : (
          <EmtyList text="등록된 공지사항이 없습니다" />
        )}
      </div>
      {isLeader ? (
        <Link to={`/room/NOTICE/write`} className="write-nav">
          <img src="/assets/room/write.svg" alt="" />
          <div className="title">공지 작성</div>
        </Link>
      ) : undefined}
    </div>
  );
};

export default RoomNotice;
